import React from "react";
import { Row } from "reactstrap";
import LiteratureTab from "../atoms/literatureTab";
import H1 from "../atoms/h1";
import LiteratureItem from "./literatureItem";
import FormContainer from "../atoms/formContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { GetResources } from "../../services/library";
import NoResults from "../atoms/noResults";
import Loading from "../atoms/loading";
import { LibrarySections } from "../../data/enums";
import Hr from "../atoms/hr";
import { Trans } from "react-i18next";

export default class LiteratureTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 1,
      articles: [],
      loaded: false,
    };
    this.changeIndex(1);
  }

  changeIndex = (tab) => {
    this.setState({ articles: [], loaded: false });
    GetResources(1, tab).then((result) => {
      if (!result.err) {
        this.setState({
          currentIndex: tab,
          articles: result.data,
          loaded: true,
        });
      }
    });
  };

  render() {
    const allItems = this.state.articles.map((item, key) => (
      <LiteratureItem key={key} item={item}></LiteratureItem>
    ));
    const alltabs = LibrarySections.map((item, key) => (
      <LiteratureTab
        key={key}
        onClick={() => this.changeIndex(item.key)}
        currentIndex={this.state.currentIndex}
        id={item.key}
        color={item.color}
      >
        {item.label}
      </LiteratureTab>
    ));
    return (
      <>
        <Row>{alltabs}</Row>
        <Hr></Hr>
        <>
          <H1>
            <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>{" "}
            <Trans>{LibrarySections[this.state.currentIndex - 1].label}</Trans>
          </H1>
          <FormContainer
            color={LibrarySections[this.state.currentIndex - 1].color}
          >
            <Row>{allItems}</Row>
            {!this.state.loaded && (
              <Row>
                <Loading size="lg"></Loading>
              </Row>
            )}
            {this.state.loaded && this.state.articles.length === 0 && (
              <NoResults></NoResults>
            )}
          </FormContainer>
        </>
      </>
    );
  }
}
