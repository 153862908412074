import React from "react";
import Layout from "../../components/layout"
import { graphql } from 'gatsby'
import Seo from "../../components/atoms/seo";
import { LIBRARY_COLOR } from "../../theme/default";
import H1 from "../../components/atoms/h1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import LibraryTabs from "../../components/organisms/libraryTabs";
import LiteratureTabs from "../../components/molecules/literatureTabs"
import { Trans, withTranslation } from "react-i18next";
import Hr from "../../components/atoms/hr";

class Literature extends React.Component {

  render() {
    return (
      <Layout color={LIBRARY_COLOR}>
          <Seo title={this.props.t("Column Literature")} keywords={[`PerkinElmer`]} />
          <LibraryTabs active={4}></LibraryTabs>
          <Hr/>
         <H1><FontAwesomeIcon icon={faBook}></FontAwesomeIcon> <Trans>Column Literature</Trans></H1>
         <p><Trans>The collection of documents provided here contain product information, beneficial tips and techniques to users of GC.</Trans></p>
          <LiteratureTabs></LiteratureTabs>
      </Layout>
    );
  }
}
export default withTranslation()(Literature);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "library"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
