import React from "react";
import styled from "styled-components";

const StyledFormContainer = styled.div`
  width: 100%;
  margin: auto;
  background-color: #f7f7f7;
  padding: 10px 15px 10px 15px;
  border: 1px solid #d7d7d7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 300px;
  margin-bottom: 10px;
  border-radius: 4px;
  margin-top: 0;
  @media screen and (max-width: 768px) {
  }
`;

const FormContainer = ({ ...props }) => {
  return (
    <StyledFormContainer
      style={{
        borderTopColor: props.color ? props.color : "#d7d7d7",
        borderTopWidth: props.color ? "15px" : "1px",
        minHeight: props.minHeight ? props.minHeight : "300px",
      }}
      {...props}
    >
      {props.children}
    </StyledFormContainer>
  );
};

export default FormContainer;
