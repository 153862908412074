export const LibrarySections = [
  { key: 1, value: 1, label: "Catalogs & Documentation", color: "#A90061" },
  { key: 2, value: 2, label: "GC Columns", color: "#4F0B7B" },
  { key: 3, value: 3, label: "Inlet Septa", color: "#58A61B" },
  { key: 4, value: 4, label: "Inlet Liners", color: "#00A1DE" },
  { key: 5, value: 5, label: "Ferrules", color: "#7D0063" },
  { key: 6, value: 6, label: "Capillary Injector Supplies", color: "#C79900" },
];

export const ChangeTypes = {
  UPPER: "UPPER",
  NONE: "NONE",
  NOTES: "NOTES",
  CHECK: "CHECK",
  PREFIX: "PREFIX",
  CUSTOM: "CUSTOM",
  DATETIME: "DATETIME",
};

export const IncidentStatus = [
  { key: "1", value: "1", label: "Manager Notified" },
  { key: "2", value: "2", label: "CAPA Investigation" },
  { key: "3", value: "3", label: "Ticket Completed" },
  { key: "4", value: "4", label: "Manager Confirmed" },
];

export const IncidentType = [
  { key: "1", value: "1", label: "Injury/Illness" },
  { key: "2", value: "2", label: "Near-Miss" },
  { key: "3", value: "3", label: "Environmental" },
  { key: "4", value: "4", label: "Property Damage" },
  { key: "5", value: "5", label: "Vehicular Accident (non-injury)" },
  { key: "6", value: "6", label: "First Aid" },
  { key: "7", value: "7", label: "Hazard ID" },
];

export const EmployeeType = [
  { key: "Employee", value: "Employee", label: "Employee" },
  { key: "Contractor", value: "Contractor", label: "Contractor" },
  { key: "Visitor", value: "Visitor", label: "Visitor" },
  { key: "Vendor", value: "Vendor", label: "Vendor" },
  { key: "Other", value: "Other", label: "Other" },
];


export const RootCause = [
  { key: "Lack of Knowledge", value: "Lack of Knowledge", label: "Lack of Knowledge" },
  { key: "Employee Placement", value: "Employee Placement", label: "Employee Placement" },
  { key: "Not Enforcing Safe Practices", value: "Not Enforcing Safe Practices", label: "Not Enforcing Safe Practices" },
  { key: "Engineering", value: "Engineering", label: "Engineering" },
  { key: "Inadequate PPE", value: "Inadequate PPE", label: "Inadequate PPE" },
  { key: "Inadequate Inspection and Maintenance Program", value: "Inadequate Inspection and Maintenance Program", label: "Inadequate Inspection and Maintenance Program" },
  { key: "Purchasing Inadequate/Inferior Equipment", value: "Purchasing Inadequate/Inferior Equipment", label: "Purchasing Inadequate/Inferior Equipment" },
  { key: "Inadequate Reward/Feedback System", value: "Inadequate Reward/Feedback System", label: "Inadequate Reward/Feedback System" },
  { key: "Unsafe Method", value: "Unsafe Method", label: "Unsafe Method" },
];
