import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import { Trans } from "react-i18next";

const StyledContainer = styled.div`
  padding: 10px;
  text-align: center;
  color: white;
  opacity: .8;
  cursor: pointer;
  margin-bottom: 10px;
  :hover{
    opacity: 1 !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
`;
const LiteratureTab = ({ ...props }) => {
  return (
    <Col   xs="6" md="4" lg="4">
      <StyledContainer { ...props }
        style={{ backgroundColor: props.color, opacity: (props.currentIndex === props.id) ? "1" : ".6" }}
      >
      <Trans>{props.children}</Trans>
      </StyledContainer>
    </Col>
  );
};

export default LiteratureTab;
